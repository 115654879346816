<template>
	<div>
		<b-row>
			<b-col sm="12">
				<b-card>
					<b-row class="text-center mb-2">
						<b-col
							sm="4"
							md="2"
							class="my-auto"
						>
							<feather-icon
								icon="CalendarIcon"
								size="1.5x"
								class="mr-1"
								style="color: rgba(216, 164, 0, 0.85);"
							/>De
						</b-col>
						<b-col
							sm="8"
							md="3"
							class="my-auto"
						>
							<flat-pickr
								v-model="starDateOne"
								class="form-control"
								:config="configCalendar"
							/>
						</b-col>
						<b-col
							sm="4"
							md="1"
							class="my-auto"
						>a</b-col>
						<b-col
							sm="8"
							md="3"
							class="my-auto"
						>
							<flat-pickr
								v-model="endDateOne"
								class="form-control"
								:config="configCalendar"
							/>
						</b-col>
						<b-col
							sm="12"
							md="3"
							class="my-auto"
						>
							<b-button
								size="sm"
								variant="primary"
								@click="filterOne"
							>
								<feather-icon
									icon="FilterIcon"
									size="1x"
								/>Filtrar
							</b-button>
						</b-col>
					</b-row>

					<!-- sorting  -->
					<b-form-group
						label="Ordenar"
						label-size="sm"
						label-align-sm="left"
						label-cols-sm="2"
						label-for="sortBySelect1"
						class="mr-1 mb-md-0"
					>
						<b-input-group size="sm">
							<b-form-select
								id="sortBySelect1"
								v-model="sortBy"
								:options="sortOption1"
							>
								<template #first>
									<option value>Ninguno</option>
								</template>
							</b-form-select>
							<b-form-select
								v-model="sortDesc"
								size="sm"
								:disabled="!sortBy"
							>
								<option :value="false">Asc</option>
								<option :value="true">Desc</option>
							</b-form-select>
						</b-input-group>
					</b-form-group>

					<!-- filter -->
					<b-form-group
						label="Fitrar"
						label-cols-sm="2"
						label-align-sm="left"
						label-size="sm"
						label-for="filterInput1"
						class="mt-1"
					>
						<b-input-group size="sm">
							<b-form-input
								id="filterInput1"
								v-model="filter"
								type="search"
								placeholder="Buscar ..."
							/>
							<b-input-group-append>
								<b-button
									:disabled="!filter"
									@click="filter = ''"
								>Limpiar</b-button>
							</b-input-group-append>
						</b-input-group>
					</b-form-group>

					<json-excel
						:data="firstTableExport()"
						name="evaluaciones_de_criticidad.xls"
					></json-excel>
					<b-table
						ref="table1Ref"
						:fields="fields"
						:items="items"
						show-empty
						empty-text="No se encontraron registros coincidentes"
						striped
						hover
						responsive
						class="position-relative text-center"
						:per-page="perPage"
						:current-page="currentPage"
						:sort-by.sync="sortBy"
						:sort-desc.sync="sortDesc"
						:sort-direction="sortDirection"
						:filter="filter"
						:filter-included-fields="filterOn"
						@filtered="onFiltered1"
					>
						<template #head()="data">
							<div v-html="data.label"></div>
						</template>
					</b-table>
				</b-card>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import { Spanish as SpanishLocale } from "flatpickr/dist/l10n/es.js"

import JsonExcel from "@core/components/b-button-download-excel/BButtonDownloadExcel.vue"
import flatPickr from "vue-flatpickr-component"
import axios from "@axios"

export default {
	components: {
		flatPickr,
		JsonExcel
	},
	data() {
		return {
			perPage: 50,
			pageOptions: [10, 15, 20, 25, 30],
			totalRows: 1,
			currentPage: 1,
			sortBy: "",
			sortDesc: false,
			sortDirection: "asc",
			filter: null,
			filterOn: [],
			fields: [],
			items: [],

			starDateOne: "",
			endDateOne: "",

			configCalendar: {
				locale: SpanishLocale,
				dateFormat: "d-m-Y"
			}
		}
	},
	computed: {
		sortOption1() {
			return this.fields
				.filter((f) => f.sortable)
				.map((f) => ({ text: f.label, value: f.key }))
		}
	},
	beforeMount() {
		this.getDataTableOne()
	},
	methods: {
		async getDataTableOne() {
			await axios.get(`/reporters/criticality-evaluations`).then((response) => {
				if (response) {
					this.fields = response.data.report.fields
					this.items = response.data.report.items

					this.totalRows = response.data.report.items.length
					this.currentPage = 1
					this.sortBy = ""
					this.sortDesc = false
					this.sortDirection = "asc"
					this.filter = null
				}
			})
		},
		async filterOne() {
			await this.getDataTableOne()
			this.$refs["table1Ref"].refresh()
		},
		firstTableExport() {
			let array = []

			this.items.forEach((item, index) => {
				let objBody = {
					ITEM: index + 1,
					"MES/AÑO": item.month,
					"DPTO RESPONSABLE": item.department_name,
					PROVEEDOR: item.provider_name,
					"TIPO DOC": item.type_name,
					"N° CONTRATO": item.contract_number,
					"DESCRIPCIÓN DE NECESIDAD": item.contract_description,
					"NIVEL DE FACTURACIÓN": item.billing_level,
					"CONTINUIDAD EN LA PRODUCCIÓN": item.production_continuity,
					"RIESGO DE ACTIVIDAD": item.activity_risk,
					"CANTIDAD DE MANO DE OBRA": item.workforce_quantity,
					"FRECUENCIA DEL SERVICIO":
						item.service_frequency > 12 ? ">12" : item.service_frequency,
					"NIVEL DE FACTURACIÓN (PESO)": item.billing_level_score,
					"CONTINUIDAD EN LA PRODUCCIÓN (PESO)":
						item.production_continuity_score,
					"RIESGO DE ACTIVIDAD (PESO)": item.activity_risk_score,
					"CANTIDAD DE MANO DE OBRA (PESO)": item.workforce_quantity_score,
					"FRECUENCIA DEL SERVICIO (PESO)": item.service_frequency_score,
					"CRITICIDAD TOTAL": item.total_score,
					NIVEL: item.criticality_name
				}

				array.push(objBody)
			})

			return array
		},
		onFiltered1(filteredItems) {
			this.totalRows = filteredItems.length
			this.currentPage = 1
		}
	}
}
</script>

<style scoped>
input[type="text"] {
	border: none;
	border-radius: 0px;
	background: transparent;
	border-bottom: 1px solid #d8d6de;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
